$break-mobile: 500px;
@font-face {
  font-family: "Frutiger LT Std";
  src: url("./assets/fonts/FrutigerLTStd-Roman.eot");
  src: url("./assets/fonts/FrutigerLTStd-Roman.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/FrutigerLTStd-Roman.woff2") format("woff2"),
    url("./assets/fonts/FrutigerLTStd-Roman.woff") format("woff"),
    url("./assets/fonts/FrutigerLTStd-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  background-color: #fff;
}
body,
html {
  margin: 0;
  padding: 0;
}
.bold {
  font-weight: 900;
  color: #000;
}
.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 16px;
}
.logo {
  position: relative;
  width: 200px;
  height: 70px;
  background-image: url("./assets/logo-archivio.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  @media screen and (max-width: $break-mobile) {
    margin: 0 auto;
    max-width: 250px;
  }
}
.logo-small {
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url("./assets/logo-small.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.splash-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-image: url("./assets/works/disco.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bio-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.bio-image {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url("./assets/superficie-a-testura-vibratile.jpg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.bio-image-b {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url("./assets/getulio-alviani-b.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.nuove-tendenze-image {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url("./assets/getulio-superficie.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.pos-neg-image {
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url("./assets/positivo-negativo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 16px auto;
}
.img-square-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.square-graphic {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/grafica.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.square-personals {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/personals.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.square-collectives {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/collettive.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.square-autentica {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/works/superficie-satinata.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.work-image {
  position: relative;
  width: 100%;
}

.credits {
  position: relative;
  margin: 32px 0;
  p {
    font-size: 14px;
    color: #999;
  }
  a {
    color: #999;
    &:active {
      color: #999;
    }
  }
}
.works {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: $break-mobile) {
    display: block;
  }
  .work {
    max-width: 50%;
    overflow: hidden;
    padding: 16px;
    margin: 0;
    box-sizing: border-box;
    @media screen and (max-width: $break-mobile) {
      max-width: 100%;
    }
  }
}
.edition {
  font-size: 16px;
  margin: auto 16px;
  &:before {
    content: "- ";
    margin: 0 0 0 -8px;
  }
  .title {
    font-style: italic;
  }
  .city {
    color: #000;
    font-weight: 900;
  }
}
.heading {
  position: relative;
  .contents {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    max-width: 800px;
    box-sizing: border-box;
    padding: 32px 16px;
    margin: 0 auto;
    justify-content: space-between;
    @media screen and (max-width: $break-mobile) {
      padding: 16px;
      display: block;
    }
  }
  .language {
    @media screen and (max-width: $break-mobile) {
      margin: 32px auto 16px auto;
      box-sizing: border-box;
      max-width: 125px;
    }
  }
}
.sticky-heading {
  background-color: #fff;
  position: fixed;
  top: 0;
  opacity: 1;
  z-index: 10;
  transition: all 0.2s ease;
  width: 100%;
  .contents {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    max-width: 800px;
    box-sizing: border-box;
    padding: 32px 16px;
    margin: 0 auto;
    justify-content: space-between;
  }
  &.hidden {
    top: -94px;
    opacity: 0;
  }
}
.menu {
  position: fixed;
  top: 94px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px;
  background-color: #fff;
  z-index: 9;
  box-sizing: border-box;
  transition: all 0.2s ease;
  opacity: 1;
  .logo-small {
    margin: 32px 16px;
  }
  .link {
    display: block;
    box-sizing: border-box;
    margin: 16px;
    color: #444;
    cursor: pointer;
    &.inner {
      margin: 16px 48px;
    }
  }
  &.hidden {
    opacity: 0;
    top: 0;
    pointer-events: none;
  }
  .first-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 0 32px 32px 32px;
    opacity: 1;
    transition: all 0.2s ease;
    box-sizing: border-box;
    &.hidden {
      left: -40px;
      opacity: 0;
      pointer-events: none;
    }
  }
  .second-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 0 32px 32px 32px;
    opacity: 1;
    transition: all 0.2s ease;
    box-sizing: border-box;
    &.hidden {
      left: 40px;
      opacity: 0;
      pointer-events: none;
    }
  }
}
.index-text {
  position: relative;
  padding: 32px 0;
  box-sizing: border-box;
}
.highlight-box {
  position: fixed;
  left: 0;
  height: auto;
  box-sizing: border-box;
  width: 100%;
  opacity: 1;
  transition: all 0.2s ease;
  z-index: 100;
  @media screen and (max-width: $break-mobile) {
    top: 0;
  }
  @media screen and (min-width: $break-mobile) {
    transform: translate3d(0, 0, 0);
    top: 150px;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
    left: 40px;
    @media screen and (max-width: $break-mobile) {
      left: 0;
      top: -50px;
    }
  }
  .contents {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    .modal {
      background-color: rgba(255, 255, 255, 0.98);
      position: relative;
      width: 60%;
      margin: 0 auto;
      top: 0;
      box-sizing: border-box;
      opacity: 0;
      transition: all 0.2s ease;
      box-shadow: 4px 4px 8px rgba(68, 68, 68, 0.3);
      @media screen and (max-width: $break-mobile) {
        width: 100%;
        height: 100vh;
        overflow: scroll;
      }
      &.visible {
        opacity: 1;
      }
      .boxed-modal-image {
        width: 100%;
        height: 200px;
        background-image: url("./assets/event.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .close-modal {
        position: absolute;
        top: 32px;
        right: 32px;
        background-color: #444;
        z-index: 10;
        box-sizing: border-box;
        height: 32px;
        width: 32px;
        color: #fff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
      .ant-img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        background: #fff;
        padding: 16px;
        box-sizing: border-box;
        .inner-content {
          position: relative;
          padding: 16px 16px 0 16px;
          background: #fff;
          .main {
            font-size: 32px;
            padding: 0;
            margin: 0;
          }
          .secondary {
            font-size: 32px;
            padding: 0;
            margin: 0;
            color: #999;
          }
          .quote {
            font-size: 24px;
            padding: 0;
            margin: 8px 0 8px 0;
          }
        }
        .link {
          position: relative;
          box-sizing: border-box;
          margin: 0 16px 16px 16px;
          padding: 8px 16px;
          line-height: 16px;
          cursor: pointer;
          color: #fff;
          background-color: #444;
          text-align: center;
        }
        .partners {
          display: flex;
          width: 100%;
          padding: 16px 16px 32px 16px;
          box-sizing: border-box;
          .skira-logo {
            width: 35%;
            height: 50px;
            background-image: url("./assets/skira-logo.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          .palazzo-lantieri-logo {
            width: 65%;
            background-image: url("./assets/palazzo-lantieri-logo.jpg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
.footer {
  position: relative;
  .contents {
    position: relative;
    max-width: 800px;
    margin: 32px auto;
    box-sizing: border-box;
    padding: 32px 16px;
    p {
      font-size: 14px;
      color: #999;
    }
    a {
      color: #999;
      &:active {
        color: #999;
      }
    }
  }
}
.clickable {
  cursor: pointer;
  margin-right: 16px;
}
body,
html,
p,
h1,
h2,
h3,
h4,
h5,
a,
a:visited,
a:active,
a:hover {
  font-family: "Frutiger LT Std", sans-serif;
  color: #444;
  text-transform: lowercase;
  text-decoration: none;
}
p {
  font-size: 16px;
  margin: 8px 0;
}
h1 {
  font-size: 28px;
  margin: 16px 0;
}
h2 {
  font-size: 20px;
  margin: 8px 0;
}

.ordered-list {
  padding: 0 16px;
  li {
    margin: 16px 0;
  }
}

.unordered-list {
  margin: 16px;
  list-style: disc;
  li {
    margin: 8px 0;
  }
}

.decorated-list {
  margin: 16px 0;
  list-style: none;
  padding: 0;
  li {
    display: block;
    line-height: 16px;
    padding: 8px;
    border: 1px solid #fff;
    transition: all 0.1s ease;
    .title {
      font-style: italic;
    }
    &:hover {
      border: 1px solid #999;
      //color: #fff;
    }
    &.rich {
      display: flex;
      padding: 32px 16px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      @media screen and (max-width: $break-mobile) {
        display: block;
      }
      .image {
        max-width: 260px;
        @media screen and (max-width: $break-mobile) {
          margin: 0 auto;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
      .text {
        padding: 8px;
        width: 320px;
        box-sizing: border-box;
        @media screen and (max-width: $break-mobile) {
          width: auto;
          max-width: 300px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
}
.pricing-table {
  margin: 16px 0;
  border-collapse: collapse;
  td {
    padding: 8px;
    border: 1px solid #000;
  }
}
